import { defineComponent, computed, reactive, ref } from '@vue/composition-api';
import { useUpdateSpacesAvailabilityMutation, BookingStatus } from '@/generated-types/graphql.types';
import { dateString, unixDate } from '@/util/dates';
import Popover from '@/components/Popover.vue';
import SpacePopover from '@/components/SpacePopover.vue';
import { unixTs, dateRange } from '@/util/dates';
import { ServerErrors } from '@/util/graphql';
import { validationMixin } from 'vuelidate';
import EventBus from '@/shared/services/eventBus';
import { useToast } from 'vue-toastification/composition';
export default defineComponent({
    components: {
        Popover,
        SpacePopover
    },
    mixins: [validationMixin],
    validations: {
        form: {
            reason: {}
        }
    },
    props: {
        value: {
            type: Object,
            required: true
        },
        allVenueSpaces: {
            type: Array,
            default: () => []
        },
        spaceIds: {
            type: Array,
            default: () => []
        },
        spacesCalendarData: {
            type: Array,
            default: () => []
        },
        isMonth: {
            type: Boolean,
            default: true
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const form = ref({
            reason: ''
        });
        const hasBookings = ref(false);
        const confirmationModal = reactive({
            visible: false,
            opened: false
        });
        const isSlotsDescriptionModalShown = ref(false);
        const spaces = computed(() => {
            return props.allVenueSpaces
                ?.filter(space => props.spaceIds.includes(space.space_id))
                .map(space => ({
                name: space.space_name,
                image: space.space_images?.[0]?.image_url,
                description: space.internal_name
            }));
        });
        const { mutate } = useUpdateSpacesAvailabilityMutation({});
        const range = computed(() => {
            if (!props.value.slotFrom || !props.value.slotTo)
                return;
            const from = unixTs(props.value.slotFrom, globalThis.$timezone);
            const to = unixTs(props.value.slotTo, globalThis.$timezone);
            if (from.isSame(to, 'day')) {
                return `${from.format('ddd, D MMM h:mm a')} - ${to.format('h:mm a')}`;
            }
            return `${from.format('D MMMM')} - ${to.format('D MMMM')}`;
        });
        const spacesActiveBookings = computed(() => {
            return props.spacesCalendarData
                .filter(day => day?.space_bookings && day.space_bookings.length > 0)
                .map(day => day.space_bookings)
                .flat()
                .filter(booking => booking?.booking_status &&
                [
                    BookingStatus.Paid,
                    BookingStatus.Pending,
                    BookingStatus.PaymentProcessing
                ].includes(booking.booking_status));
        });
        const onClose = () => {
            const hasBookingsAWithinClosingSlots = spacesActiveBookings.value.some(booking => {
                if (booking &&
                    booking.slot_start &&
                    booking.slot_end &&
                    props.value.slotTo &&
                    props.value.slotFrom) {
                    const bookingRange = dateRange(unixDate(booking?.slot_start), unixDate(booking.slot_end));
                    const availabilityRange = dateRange(props.value.slotFrom, props.value.slotTo);
                    return availabilityRange.intersect(bookingRange);
                }
                return false;
            });
            if (hasBookingsAWithinClosingSlots) {
                hasBookings.value = true;
            }
            onUpdate({ opened: false });
        };
        const toast = useToast();
        const onUpdate = async ({ opened = confirmationModal.opened, reason = null, form = null }) => {
            if (!confirmationModal.visible) {
                return Object.assign(confirmationModal, {
                    visible: true,
                    opened
                });
            }
            confirmationModal.visible = false;
            if (form && form.$model.reason) {
                form.$reset();
            }
            if (!props.value.slotFrom || !props.value.slotTo) {
                return;
            }
            await mutate({
                input: {
                    is_month: props.isMonth,
                    spaces_ids: props.spaceIds,
                    slot_from: dateString(props.value.slotFrom, globalThis.$timezone),
                    slot_to: dateString(props.value.slotTo, globalThis.$timezone),
                    is_opened: opened,
                    reason
                }
            })
                .then(() => {
                if (opened) {
                    toast.success(root.$tc('booking.availability.save.notifications.opened', props.spaceIds.length));
                }
                else {
                    toast.error(root.$tc('booking.availability.save.notifications.closed', props.spaceIds.length));
                }
                EventBus.$emit('spacesAvailabilityUpdated', {});
            })
                .catch(({ graphQLErrors }) => {
                if (graphQLErrors) {
                    const graphQLError = ServerErrors.extractGQLError(graphQLErrors);
                    const errorKey = 'availability_management.non_working_day_selected';
                    const toastText = graphQLError?.error?.key === errorKey
                        ? root.$i18n.t(`common.errors.${errorKey}`)
                        : root.$i18n.t('booking.availability.save.notifications.error');
                    toast.error(toastText);
                }
            });
            emit('input', {
                slotFrom: null,
                slotTo: null
            });
        };
        return {
            confirmationModal,
            form,
            hasBookings,
            isSlotsDescriptionModalShown,
            range,
            spaces,
            onClose,
            onUpdate
        };
    }
});
