import { computed, defineComponent, provide, ref, reactive, watch } from '@vue/composition-api';
import VenueSelector from '@/components/VenueSelector.vue';
import SpaceSelector from '@/components/SpaceSelector/SpaceSelector.vue';
import BackButton from '@/components/BackButton.vue';
import AvailabilityProvider from '@/Booking/Availability/components/AvailabilityProvider.vue';
import AvailabilitySaver from '@/Booking/Availability/components/AvailabilitySaver.vue';
import Calendar from '@/Booking/Availability/components/AvailabilityCalendar.vue';
import WeekCalendar from '@/Booking/Availability/components/WeekCalendar.vue';
import Toggle, { Option } from '@/uikit/Toggle.vue';
import { useGetVenuesQuery, useVenueWorkingTimeQuery } from '@/generated-types/graphql.types';
import { monthsRange, currentYear, onMonthEvent } from '@/util/dates';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { CalendarViews } from '@/Calendar/calendar.const';
import { withAuthQueryHook } from '@/util/hooks';
import EmptyView from '@/components/EmptyView.vue';
import { BookingRoutes } from '@/Booking/booking.const';
export default defineComponent({
    name: 'AvailabilityManagement',
    components: {
        VenueSelector,
        SpaceSelector,
        AvailabilityProvider,
        AvailabilitySaver,
        Calendar,
        WeekCalendar,
        Toggle,
        Option,
        BackButton,
        EmptyView
    },
    setup(_, context) {
        const { root } = context;
        const venueId = ref(0);
        const spaceIds = ref([]);
        const allVenueSpaces = ref([]);
        const isCalendarInGuestMode = ref(false);
        // Props drilling to deep children: MonthView.vue, WeekView.vue, DayWeekView.vue
        provide('isCalendarInGuestMode', isCalendarInGuestMode);
        // Get all user venues
        const { result: getAllVenues } = withAuthQueryHook(useGetVenuesQuery)({}, { clientId: 'legacy' });
        const venues = computed(() => getAllVenues.value?.viewerApiKey?.venuesGet);
        const onVenueSelected = (_venueId) => {
            venueId.value = _venueId;
            if (venueId.value !== +root.$route.params.venueId) {
                globalThis.$router.replace({
                    params: { venueId: venueId.value },
                    query: root.$route.query
                });
            }
        };
        const onSpacesSelected = (_spaceIds) => {
            spaceIds.value = _spaceIds;
            globalThis.$router.replace({
                query: { ...root.$route.query, spaces: _spaceIds }
            });
        };
        const view = computed({
            get() {
                return root.$route.query.view;
            },
            set(view) {
                globalThis.$router.replace({
                    query: { ...root.$route.query, view }
                });
            }
        });
        // Default view mode is month
        if (!view.value)
            view.value = CalendarViews.MONTH;
        const day = computed(() => globalThis.$store.getters['$_calendar/selectedDay']);
        const fullDayMode = ref(false);
        const queryVariables = reactive({
            venueId: +venueId.value
        });
        const queryOptions = reactive({
            enabled: false,
            fetchPolicy: 'no-cache'
        });
        const { result: venueResult, refetch: refetchVenueResult } = useVenueWorkingTimeQuery(() => queryVariables, () => queryOptions);
        const spaces = computed(() => venueResult?.value?.specific_venue.venue_spaces);
        watch(() => spaceIds.value, () => {
            if (spaceIds.value.length && spaces.value?.length) {
                allVenueSpaces.value = spaces.value.filter(space => space && spaceIds.value.includes(space.space_id));
            }
        }, { immediate: true });
        watch(() => root.$route.params.venueId, () => {
            if (+root.$route.params.venueId) {
                venueId.value = +root.$route.params.venueId;
                queryVariables.venueId = venueId.value;
                queryOptions.enabled = true;
                refetchVenueResult(queryVariables);
            }
        }, { immediate: true });
        const workingTime = computed(() => venueResult?.value?.specific_venue?.working_time);
        const months = ref(monthsRange());
        const year = ref(currentYear);
        const onMonth = (date) => {
            const res = onMonthEvent(date, globalThis.$timezone);
            months.value = res.months;
            year.value = res.year;
        };
        const selectedSlots = ref({
            slotFrom: null,
            slotTo: null
        });
        return {
            allVenueSpaces,
            day,
            fullDayMode,
            months,
            selectedSlots,
            spaces,
            spaceIds,
            venueId,
            venues,
            view,
            workingTime,
            year,
            BookingRoutes,
            CalendarViews,
            onMonth,
            onSpacesSelected,
            onVenueSelected,
            useGetLocalizedPath
        };
    }
});
