import ModalPopup from '@/shared/components/ModalPopup.vue';
import { defineComponent } from '@vue/composition-api';
import { useDeactivateCouponMutation } from '@/generated-types/graphql.types';
import { useToast } from 'vue-toastification/composition';
export default defineComponent({
    name: 'DeleteCouponModal',
    components: {
        ModalPopup
    },
    props: {
        couponId: {
            type: Number,
            required: true
        }
    },
    setup(props, { emit, root }) {
        const { mutate } = useDeactivateCouponMutation({});
        const toast = useToast();
        const handleDeleteCoupon = () => {
            mutate({ couponId: props.couponId }).then(() => {
                toast.success(root.$i18n.t('coupon.toast_messages.coupon_deactivated'));
                emit('onDelete');
                emit('close');
            });
        };
        return {
            handleDeleteCoupon
        };
    }
});
