import { computed, defineComponent, watch } from '@vue/composition-api';
import { DATE_FORMATS } from '@/Calendar/const';
import Calendar from '@/Calendar/we-calendar.vue';
import { dateFormat, getToday } from '@/util/dates';
export default defineComponent({
    name: 'VenueCalendar',
    components: {
        Calendar
    },
    props: {
        venueFormattedSchedule: {
            type: Object,
            default: () => { }
        },
        size: { type: String, default: 'normal' },
        startDate: {
            type: Date,
            default: null
        },
        spaces: {
            type: Array,
            default: () => []
        },
        spacesCalendarData: {
            type: Array,
            default: () => []
        }
    },
    setup(props) {
        const initialSlots = {
            slotFrom: null,
            slotTo: null
        };
        const spaceIds = computed(() => props.spaces ? props.spaces.map(el => el.spaceId) : []);
        const selectedDay = computed(() => globalThis.$store.getters['$_calendar/selectedDay']);
        watch(() => selectedDay.value, () => {
            let day = selectedDay.value;
            if (!day) {
                day = getToday();
            }
            globalThis.$store.dispatch('$_calendar/setCurrentDay', dateFormat(day, DATE_FORMATS.calendarCore));
        }, { immediate: true });
        return {
            initialSlots,
            selectedDay,
            spaceIds
        };
    }
});
